// React and Packages
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { getFintoc } from '@fintoc/fintoc-js';
import { Kushki } from '@kushki/js';
import { useSnackbar } from 'notistack';

// MUI
import {
  Container,
  Paper,
  Typography,
  Modal,
  Button,
  Backdrop,
  CircularProgress,
} from '@mui/material';

// Styles
import cStyles from '../../styles/common.module.scss';
import styles from './PaymentMethods.module.scss';

// API
import { buyerApi } from '../../api';
import { KushkiResponse } from '../../../app/type';

export interface NewComponentProps {
  externalFormRef: React.MutableRefObject<
    { submitForm: (payment_method: string) => void } | undefined
  >;
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  selectPaymentMethodForApproval: (paymentMethod: string) => void;
  setCardInfo: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
  kushkiTokenFail: boolean;
  extraArgs?: { [key: string]: any };
  children?: React.ReactNode;
}

let cardForm: any;
let paymentMethodTimer: number | undefined = undefined;
const kushki = new Kushki({
  merchantId:
    process.env.NODE_ENV === 'production'
      ? '1804b8f0b64549adb59dd76c6736124d'
      : 'c417cbc305344419a2c765e11ac7c7b3',
  inTestEnvironment: process.env.NODE_ENV !== 'production',
});

const AddPaymentMethods = (
  OriginalComponent: React.FunctionComponent<NewComponentProps>
): (() => React.ReactElement) => {
  function NewComponent() {
    const history = useHistory();
    const [mpIssuer, setMpIssuer] = useState<string>('none');
    const [cardData, setCardData] = useState<any>({});
    const [mercadoPagoLoaded, setMercadoPagoLoaded] = useState<boolean>(false);
    const [machModalOpen, setMachModalOpen] = useState<boolean>(false);
    const formRef = useRef(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<{ [key: string]: any }>({});
    const [submitting, setSubmitting] = useState<boolean>(false);
    const externalFormRef = useRef<{ submitForm: (payment_method: string) => void }>();
    const mercadoPagoRef = useRef<{ click: () => void }>();
    const [kushkiInfo, setKushkiInfo] = useState<{ [key: string]: any }>({});
    const [kushkiTokenFail, setKushkiTokenFail] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      document.getElementById('mercadoPagoScript') &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        typeof MercadoPago !== 'undefined' &&
        setMercadoPagoLoaded(true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    }, [document.getElementById('mercadoPagoScript'), typeof MercadoPago]);

    const mercadoPagoScript = useMemo(() => {
      if (mercadoPagoLoaded) {
        const mercadoPagoKey =
          process.env.NODE_ENV === 'production'
            ? 'APP_USR-65a61ab1-198e-4f9d-aa73-374263572a52'
            : 'TEST-0c9a2057-a2a5-472e-bf8c-1e7aa34c0d5a';
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return new MercadoPago(mercadoPagoKey);
      } else return false;
    }, [mercadoPagoLoaded]);

    useEffect(() => {
      if (mercadoPagoScript) {
        cardForm = mercadoPagoScript.cardForm({
          amount: '100',
          iframe: true,
          form: {
            id: 'formCheckout',
            cardNumber: {
              id: 'formCheckoutCardNumber',
              placeholder: 'Numero de tarjeta',
            },
            expirationDate: {
              id: 'formCheckoutExpirationDate',
              placeholder: 'MM/YY',
            },
            securityCode: {
              id: 'formCheckoutSecurityCode',
              placeholder: 'Código de seguridad',
            },
            cardholderName: {
              id: 'formCheckoutCardholderName',
              placeholder: 'Titular de la tarjeta',
            },
            issuer: {
              id: 'formCheckoutIssuer',
              placeholder: 'Banco emisor',
            },
            installments: {
              id: 'formCheckoutInstallments',
              placeholder: 'Cuotas',
            },
            identificationType: {
              id: 'formCheckoutIdentificationType',
              placeholder: 'Tipo de documento',
            },
            identificationNumber: {
              id: 'formCheckoutIdentificationNumber',
              placeholder: 'Número del documento',
            },
            cardholderEmail: {
              id: 'formCheckoutCardholderEmail',
              placeholder: 'E-mail',
            },
          },
          callbacks: {
            onFormMounted: (error: any) => {
              if (error) return console.warn('Form Mounted handling error: ', error);
              return;
            },
            onFetching: () => {
              return;
            },
            onPaymentMethodsReceived: (error: any, data?: { [key: string]: string }[]) => {
              if (error) return console.warn('Error while trying : ', error);
              if (data) setMpIssuer(data[0]['id']);
            },
            onSubmit: (event: any) => {
              event.preventDefault();
              const formData = cardForm.getCardFormData();
              setCardData(formData);
              // externalFormRef.current?.submitForm();
            },
          },
        });
      }
    }, [mercadoPagoScript]);

    const gateways = (): React.ReactElement | undefined => {
      if (
        ['webpay_plus', 'oneclick'].includes(selectedPaymentMethod?.payment_method) &&
        selectedPaymentMethod?.url
      ) {
        return (
          <form method="POST" action={selectedPaymentMethod.url} ref={formRef}>
            <input type="hidden" name="TBK_TOKEN" value={selectedPaymentMethod.token} />
          </form>
        );
      } else if (selectedPaymentMethod?.payment_method === 'mach' && selectedPaymentMethod?.url) {
        return (
          <Modal open={machModalOpen} onClose={() => setMachModalOpen(false)}>
            <Container maxWidth="sm" style={{ margin: 'auto' }}>
              <Paper className={`${cStyles.modal} ${styles.modal}`}>
                <div className={styles.modalHeader}>
                  <Typography variant="h4">Para pagar escanea el siguiente código</Typography>
                </div>
                <QRCode title="Pago MACH" value={selectedPaymentMethod?.url} />
                <Typography variant="h6">o si estás en tu teléfono</Typography>
                <Button variant="contained" color="primary" href={selectedPaymentMethod?.url}>
                  abre MACH
                </Button>
                <Typography variant="h6">
                  y espera a ser redirigido a la vista de confirmación.
                </Typography>
              </Paper>
            </Container>
          </Modal>
        );
      }
    };

    useEffect(() => {
      if (['webpay_plus', 'oneclick'].includes(selectedPaymentMethod?.payment_method)) {
        if (selectedPaymentMethod?.url && formRef?.current) {
          // @ts-expect-error: formRef.current could be undefined
          formRef?.current.submit();
        }
      } else if (selectedPaymentMethod?.payment_method === 'mach') {
        setMachModalOpen(true);
        setSubmitting(false);
        if (paymentMethodTimer) clearInterval(paymentMethodTimer);
        paymentMethodTimer = setInterval(() => {
          buyerApi.mach
            .check_status(selectedPaymentMethod?.payment_id)
            .then((data: any) => {
              if (['COMPLETED', 'CONFIRMED'].includes(data.status)) {
                if (paymentMethodTimer) clearInterval(paymentMethodTimer);
                data.success_url
                  ? (location.href = data.success_url)
                  : history.push(`/subscription/success?payment_id=${data.payment_id}`);
              } else if (['EXPIRED', 'REVERSED'].includes(data.status)) {
                if (paymentMethodTimer) clearInterval(paymentMethodTimer);
                data.failure_url
                  ? (location.href = data.failure_url)
                  : history.push('/subscription/error');
              }
            })
            .catch();
        }, 5000) as unknown as number;
      } else if (selectedPaymentMethod?.payment_method === 'fintoc_payment') {
        setSubmitting(true);
        getFintoc()
          .then((Fintoc: any) => {
            const widget = Fintoc.create({
              widgetToken: selectedPaymentMethod?.widget_token,
              publicKey: selectedPaymentMethod?.public_key,
              holderType: 'individual',
              product: 'payments',
              webhookUrl: selectedPaymentMethod?.webhook_url,
              onSuccess: () => {
                buyerApi.fintoc
                  .check_status(selectedPaymentMethod?.payment_id)
                  .then((data) =>
                    data.success_url
                      ? (location.href = data.success_url)
                      : history.push(`/subscription/success?payment_id=${data.payment_id}`)
                  )
                  .catch((error: any) =>
                    error.response.data.failure_url
                      ? (location.href = error.response.data.failure_url)
                      : history.push('/subscription/error')
                  );
              },
            });
            widget.open();
          })
          .finally(() => setSubmitting(false));
      } else if (
        ['et_pay_business', 'recurrent_et_pay'].includes(selectedPaymentMethod?.payment_method)
      ) {
        location.href = selectedPaymentMethod.redirect_url;
      } else if (selectedPaymentMethod?.payment_method === 'recurrent_fintoc') {
        setSubmitting(true);
        getFintoc()
          .then((Fintoc: any) => {
            const widget = Fintoc.create({
              widgetToken: selectedPaymentMethod?.widget_token,
              publicKey: selectedPaymentMethod?.public_key,
              holderType: 'individual',
              product: 'subscriptions',
              webhookUrl: selectedPaymentMethod?.webhook_url,
              onSuccess: () => {
                history.push(`/subscription/pending?inscription=true&message=pendiente`);
              },
              onExit: () => {
                history.push('/subscription/error');
              },
            });
            widget.open();
          })
          .finally(() => setSubmitting(false));
      } else if (selectedPaymentMethod?.payment_method === 'recurrent_khipu') {
        setSubmitting(true);
        selectedPaymentMethod['approved']
          ? (window.location.href = selectedPaymentMethod['redirect_url'])
          : enqueueSnackbar('Ha ocurrido un problema con el proveedor, intenta nuevamente', {
              variant: 'error',
            });
        setSubmitting(false);
      }

      if (selectedPaymentMethod?.payment_method !== 'mach' && paymentMethodTimer) {
        clearInterval(paymentMethodTimer);
      }

      return () => {
        if (paymentMethodTimer) clearInterval(paymentMethodTimer);
      };
    }, [selectedPaymentMethod, formRef]);

    const selectPaymentMethodForApproval = (method: string) => {
      if (method === 'mach') {
        setMachModalOpen(true);
        externalFormRef.current?.submitForm(method);
        return;
      }
      if (method === 'mercado_pago') {
        mercadoPagoRef.current?.click();
        return;
      }
      externalFormRef.current?.submitForm(method);
    };

    const setCardInfo = (arg: { [key: string]: any }) => {
      setKushkiTokenFail(false);
      const card = {
        name: arg.name,
        number: arg.card_number.split(' ').join(''),
        cvc: arg.cvv,
        expiryMonth: arg.expiration_date.slice(0, 2),
        expiryYear: arg.expiration_date.slice(3, 5),
      };
      arg.payment_method === 'kushki_single'
        ? kushki.requestToken(
            {
              amount: arg.amount,
              currency: 'CLP',
              card: card,
            },
            (response: KushkiResponse) => {
              if (!response.code) {
                setKushkiInfo(response);
                externalFormRef.current?.submitForm(arg.payment_method);
              } else {
                setKushkiTokenFail(true);
                console.error(response.error);
              }
            }
          )
        : kushki.requestSubscriptionToken(
            {
              currency: 'CLP',
              card: card,
            },
            (response: KushkiResponse) => {
              if (!response.code) {
                setKushkiInfo(response);
                externalFormRef.current?.submitForm(arg.payment_method);
              } else {
                setKushkiTokenFail(true);
                console.error(response.error);
              }
            }
          );
    };

    return (
      <div>
        {submitting && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={submitting}
          >
            <CircularProgress color="secondary" />
          </Backdrop>
        )}
        <OriginalComponent
          externalFormRef={externalFormRef}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          selectPaymentMethodForApproval={selectPaymentMethodForApproval}
          setCardInfo={setCardInfo}
          kushkiTokenFail={kushkiTokenFail}
          extraArgs={{
            mpIssuer: mpIssuer,
            cardData: cardData,
            mercadoPagoLoaded: mercadoPagoLoaded,
            mercadoPagoRef: mercadoPagoRef,
            kushkiInfo: kushkiInfo,
          }}
        />
        {gateways()}
      </div>
    );
  }

  return NewComponent;
};

export default AddPaymentMethods;
