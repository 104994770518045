import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from '../../../app/hooks';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { es } from 'yup-locales';

yup.setLocale(es);

// Types
import {
  ApiObject,
  Buyer,
  ExtraField,
  InvoiceConfiguration,
  InvoiceInformation,
  PaymentLinkConfiguration,
  ShippingInformation,
} from '../../../app/type';
import variables from '../../../common/styles/variables.module.scss';

// Features
import { SellerState } from '../sellerSlice';

// Components
import { Grid, Button, TextField, CircularProgress, Typography, MenuItem } from '@mui/material';

// API
import styles from './Index.module.scss';
import { sellerApi } from '../../../common/api';
import { useSnackbar } from 'notistack';
import { downcase, formatRUT, validateRut } from '../../../common/utils';
import { REGIONS } from '../../../common/constants/regions';
import { COMMUNES_BY_REGION } from '../../../common/constants/communes';
import { useParams } from 'react-router-dom';

interface BuyerInfo {
  id?: string;
  company_id: string;
  name: string;
  email: string;
  extra_fields: { [key: string]: string };
  shipping_information?: ShippingInformation;
  invoice_information?: InvoiceInformation;
}

interface BuyerFormProps {
  initialData?: BuyerInfo;
  actionLabel?: string;
  onSuccess?: (
    data: ApiObject<Buyer>,
    extraFields?: { [key: string]: string },
    invoiceInformationId?: string
  ) => void;
  extraActions?: React.ReactElement;
  loading?: boolean;
  setLoading: (l: boolean) => void;
  questions: ExtraField[];
}

export const BuyerForm = (props: BuyerFormProps): React.ReactElement => {
  const { company, subscription, singlePayment } = useSelector(
    ({ seller }: { seller: SellerState }) => seller
  );
  const { subscriptionId, singlePaymentId } =
    useParams<{ subscriptionId?: string; singlePaymentId?: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { initialData, actionLabel = 'Guardar', onSuccess = () => null, extraActions } = props;
  const service = subscriptionId ? subscription : singlePayment;
  const [shippingConfiguration, setShippingConfiguration] = useState<PaymentLinkConfiguration>();
  const [invoiceConfiguration, setInvoiceConfiguration] = useState<InvoiceConfiguration>();
  const [invoiceDocument, setInvoiceDocument] = useState<string>();

  const BuyerSchema = useMemo(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return yup.object().shape({
      name: yup.string().min(3).max(100).required().label('Nombre'),
      email: yup
        .string()
        .email()
        .matches(emailRegex, 'Email inválido, no puede contener caracteres especiales')
        .required()
        .label('Email')
        .test('gmail-domain', 'Dominio del correo inválido, debe terminar en .com', (value) => {
          if (!value) return true;
          const domain = value.split('@')[1];
          if (domain && (domain.startsWith('gmail') || domain.startsWith('hotmail'))) {
            return domain === 'gmail.com' || domain === 'hotmail.com';
          }
          return true;
        }),
    });
  }, []);

  const buyerShippingSchema = useMemo(() => {
    return yup.object().shape({
      address1: yup.string().required().label('Dirección'),
      region: yup.string().required().label('Región'),
      commune: yup.string().required().label('Comuna'),
    });
  }, []);

  const submitFormik = (buyerInfo: BuyerInfo) => {
    props.setLoading(true);
    if (!validateRutExtraField()) {
      props.setLoading(false);
      return;
    }
    const request = props.initialData?.id ? sellerApi.buyers.update : sellerApi.buyers.create;
    request({ data: buyerInfo })
      .then((data: ApiObject<Buyer>) => {
        onSuccess(data, buyerInfo.extra_fields, data.data.invoice_informations?.[0]?.id);
      })
      .catch((err: any): void => {
        if (err?.response?.status === 400 && err.response.data?.message === 'email_taken') {
          enqueueSnackbar('Correo ya está en uso', {
            variant: 'error',
          });
        } else if (err?.response?.status === 403) {
          enqueueSnackbar('Necesitas ser administrador para realizar esta acción', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Ocurrió un error, vuelva a intentarlo', { variant: 'error' });
        }
      })
      .finally(() => {
        props.setLoading(false);
      });
  };

  const addShippingInformation = (shippingInfo: ShippingInformation) => {
    formik.setFieldValue('shipping_information', shippingInfo);
    if (!props.initialData && service?.emit_document && invoiceConfiguration) {
      formik.setFieldValue('invoice_information', invoiceInformationFormik.values);
      submitFormik({
        ...formik.values,
        shipping_information: shippingInfo,
        invoice_information: invoiceInformationFormik.values,
      });
    } else {
      submitFormik({ ...formik.values, shipping_information: shippingInfo });
    }
  };

  const shippingFormik = useFormik<ShippingInformation>({
    initialValues: {
      address1: '',
      address2: '',
      country: 'Chile',
      commune: '',
      region: '',
      phone: '',
    },
    validationSchema: () => buyerShippingSchema,
    onSubmit: addShippingInformation,
  });

  const addInvoiceInformation = (invoiceInfo: InvoiceInformation) => {
    formik.setFieldValue('invoice_information', invoiceInfo);
    submitFormik({ ...formik.values, invoice_information: invoiceInfo });
  };

  const invoiceInformationSchema = useMemo(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const conditionalRequired = (fieldName: string) =>
      invoiceDocument === 'invoice'
        ? yup.string().required().label(fieldName)
        : yup.string().label(fieldName);
    const rutConditionalRequired = (fieldName: string) =>
      invoiceDocument === 'invoice' ||
      (invoiceDocument === 'receipt' && invoiceConfiguration?.ask_rut)
        ? yup.string().required().label(fieldName)
        : yup.string().label(fieldName);

    return yup.object().shape({
      region: conditionalRequired('Región'),
      commune: conditionalRequired('Comuna'),
      address: conditionalRequired('Dirección'),
      rut: rutConditionalRequired('Rut').test(
        'is-valid-rut',
        'Rut ingresado inválido',
        (value) => !value || validateRut(value)
      ),
      business_name: conditionalRequired('Razón social'),
      activity: conditionalRequired('Giro'),
      email: yup
        .string()
        .email()
        .matches(emailRegex, 'Email inválido, no puede contener caracteres especiales')
        .label('Email de contacto')
        .test('gmail-domain', 'Dominio del correo inválido, debe terminar en .com', (value) => {
          if (!value) return true;
          const domain = value.split('@')[1];
          if (domain && (domain.startsWith('gmail') || domain.startsWith('hotmail'))) {
            return domain === 'gmail.com' || domain === 'hotmail.com';
          }
          return true;
        }),
    });
  }, [service, invoiceDocument]);

  const invoiceInformationFormik = useFormik<InvoiceInformation>({
    initialValues: {
      id: '',
      region: '',
      commune: '',
      address: '',
      rut: '',
      business_name: '',
      activity: '',
      email: '',
    },
    validationSchema: () => invoiceInformationSchema,
    onSubmit: addInvoiceInformation,
    enableReinitialize: true,
  });

  const formik = useFormik<BuyerInfo>({
    initialValues:
      initialData ||
      ({
        company_id: company?.id,
        name: '',
        email: '',
        extra_fields: {},
        shipping_information: undefined,
      } as BuyerInfo),
    validationSchema: () => BuyerSchema,
    onSubmit: service?.ask_shipping_address
      ? shippingFormik.submitForm
      : !props.initialData && service?.emit_document && invoiceConfiguration
      ? invoiceInformationFormik.submitForm
      : submitFormik,
  });

  const handleExtraFieldChange = (event: any, id: string, question: string) => {
    if (downcase(question) === 'rut') {
      formik.setFieldValue('extra_fields', {
        ...formik.values.extra_fields,
        [id]: formatRUT(event.target.value),
      });
    } else {
      formik.setFieldValue('extra_fields', {
        ...formik.values.extra_fields,
        [id]: event.target.value,
      });
    }
  };

  const validateRutExtraField = () => {
    const rutExtraFields = (props.questions || {}).filter(
      (object) => downcase(object.question) === 'rut'
    );
    if (rutExtraFields.length === 0) return true;
    let validRuts = true;
    rutExtraFields.forEach((ef) => {
      const valid = validateRut(formik.values.extra_fields[ef.id]);
      if (!valid) {
        formik.setFieldError(`extra_fields.${ef.id}`, `${ef.question} inválido`);
        validRuts = false;
      }
    });
    return validRuts;
  };

  useEffect(() => {
    if (service) {
      if (subscriptionId) {
        sellerApi.subscriptions
          .shippingConfiguration(service.id || '')
          .then((data: ApiObject<PaymentLinkConfiguration>) => {
            setShippingConfiguration(data.data);
          })
          .catch(console.error);
        if (service.emit_document) {
          sellerApi.subscriptions
            .invoiceConfiguration(service.id || '')
            .then((data: ApiObject<InvoiceConfiguration>) => {
              setInvoiceConfiguration(data.data);
            })
            .catch(console.error);
        }
      } else if (singlePaymentId) {
        sellerApi.singlePayments
          .shippingConfiguration(service.id || '')
          .then((data: ApiObject<PaymentLinkConfiguration>) => {
            setShippingConfiguration(data.data);
          })
          .catch(console.error);
        if (service.emit_document) {
          sellerApi.singlePayments
            .invoiceConfiguration(service.id || '')
            .then((data: ApiObject<InvoiceConfiguration>) => {
              setInvoiceConfiguration(data.data);
            })
            .catch(console.error);
        }
      }
    }
  }, [service]);

  useEffect(() => {
    if (invoiceConfiguration)
      setInvoiceDocument(invoiceConfiguration.document === 'invoice' ? 'invoice' : 'receipt');
  }, [invoiceConfiguration]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            id="name"
            label="Nombre"
            type="text"
            autoComplete="name"
            variant="outlined"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            id="email"
            name="email"
            label="Correo"
            type="email"
            autoComplete="email"
            variant="outlined"
            value={formik.values.email || ''}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} marginTop={2}>
        {props.questions.length !== 0 && (
          <Grid item xs={12}>
            <Typography variant="h6" color={variables.primaryDarkerColor}>
              <b>Datos Adicionales</b>
            </Typography>
          </Grid>
        )}
        {props.questions.map((object: ExtraField) => (
          <Grid item xs={12} md={6} key={object.id}>
            {object.kind === 'string' ? (
              <TextField
                fullWidth
                required={object.mandatory}
                id={object.question}
                name={object.question}
                label={object.question}
                type="text"
                autoComplete="formik.values.extra_fields[key]"
                variant="outlined"
                value={formik.values.extra_fields[object.id] || ''}
                onChange={(event) => handleExtraFieldChange(event, object.id, object.question)}
                error={Boolean(formik.errors?.extra_fields?.[object.id])}
                helperText={formik.errors?.extra_fields?.[object.id] || ''}
              />
            ) : (
              <TextField
                fullWidth
                required={object.mandatory}
                id={object.question}
                name={object.question}
                label={object.question}
                select
                variant="outlined"
                value={formik.values.extra_fields[object.id] || ''}
                onChange={(event) =>
                  formik.setFieldValue('extra_fields', {
                    ...formik.values.extra_fields,
                    [object.id]: event.target.value,
                  })
                }
                error={Boolean(formik.errors?.extra_fields?.[object.id])}
                helperText={formik.errors?.extra_fields?.[object.id] || ''}
              >
                {object.selector_options?.split(',').map((option: string) => (
                  <MenuItem key={`${option}`} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
        ))}
        {service?.ask_shipping_address && (
          <Fragment>
            <Grid item xs={6} className={styles.as}>
              <TextField
                fullWidth
                required
                select
                id="region"
                label="Región"
                type="text"
                autoComplete="region"
                variant="outlined"
                value={shippingFormik.values.region}
                onChange={(event) => shippingFormik.setFieldValue('region', event.target.value)}
                error={shippingFormik.touched.region && Boolean(shippingFormik.errors?.region)}
                helperText={shippingFormik.touched.region && shippingFormik.errors?.region}
              >
                {shippingConfiguration?.shipping_one_region ? (
                  <MenuItem value={shippingConfiguration.shipping_regions[0]}>
                    {shippingConfiguration.shipping_regions[0]}
                  </MenuItem>
                ) : shippingConfiguration?.shipping_regions ? (
                  shippingConfiguration?.shipping_regions.map((region) => (
                    <MenuItem key={region} value={region}>
                      {region}
                    </MenuItem>
                  ))
                ) : (
                  REGIONS.map((region) => (
                    <MenuItem key={region[0]} value={region[1]}>
                      {region[1]}
                    </MenuItem>
                  ))
                )}
              </TextField>
            </Grid>
            <Grid item xs={6} className={styles.inputContainer}>
              <TextField
                fullWidth
                required
                select
                id="commune"
                type="text"
                label="Comuna"
                autoComplete="commune"
                variant="outlined"
                value={shippingFormik.values.commune}
                onChange={(event) => shippingFormik.setFieldValue('commune', event.target.value)}
                error={shippingFormik.touched.commune && Boolean(shippingFormik.errors?.commune)}
                helperText={shippingFormik.touched.commune && shippingFormik.errors?.commune}
              >
                {shippingFormik.values.region ? (
                  shippingConfiguration?.shipping_communes_by_region ? (
                    (
                      shippingConfiguration?.shipping_communes_by_region[
                        shippingFormik.values.region
                      ] || COMMUNES_BY_REGION[shippingFormik.values.region]
                    )
                      ?.sort()
                      .map((commune) => (
                        <MenuItem key={commune} value={commune}>
                          {commune}
                        </MenuItem>
                      ))
                  ) : (
                    COMMUNES_BY_REGION[shippingFormik.values.region]?.sort().map((commune) => (
                      <MenuItem key={commune} value={commune}>
                        {commune}
                      </MenuItem>
                    ))
                  )
                ) : (
                  <MenuItem>Selecciona región</MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                id="address1"
                type="text"
                label="Dirección"
                autoComplete="address1"
                variant="outlined"
                value={shippingFormik.values.address1}
                onChange={shippingFormik.handleChange}
                error={shippingFormik.touched.address1 && Boolean(shippingFormik.errors?.address1)}
                helperText={shippingFormik.touched.address1 && shippingFormik.errors?.address1}
              />
            </Grid>
          </Fragment>
        )}
        {!props.initialData?.id && service?.emit_document && invoiceConfiguration && (
          <Fragment>
            {invoiceConfiguration.document === 'selectable' && (
              <Grid item xs={12} className={styles.inputContainer}>
                <Typography className={styles.fieldHeader}>
                  Documento <span className={styles.mandatoryText}>*</span>
                </Typography>
                <TextField
                  className={styles.inputField}
                  id="invoiceDocument"
                  fullWidth
                  select
                  variant="outlined"
                  name="invoiceDocument"
                  defaultValue="receipt"
                  value={invoiceDocument}
                  onChange={(event) => setInvoiceDocument(event.target.value)}
                >
                  <MenuItem value="receipt">Boleta</MenuItem>
                  <MenuItem value="invoice">Factura</MenuItem>
                </TextField>
              </Grid>
            )}
            {invoiceDocument === 'invoice' ? (
              <Fragment>
                <Grid item xs={12} className={styles.inputContainer}>
                  <Typography variant="h6" color={variables.primaryDarkerColor}>
                    <b>Datos factura</b>
                  </Typography>
                  <Typography>
                    Rut <span className={styles.mandatoryText}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    id="rut"
                    type="text"
                    variant="outlined"
                    value={invoiceInformationFormik.values.rut}
                    onChange={(e) =>
                      invoiceInformationFormik.setFieldValue('rut', formatRUT(e.target.value))
                    }
                    InputLabelProps={{ shrink: !!invoiceInformationFormik.values.rut }}
                    error={Boolean(invoiceInformationFormik.errors?.rut)}
                    helperText={invoiceInformationFormik.errors?.rut}
                  />
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <Typography>
                    Razón Social <span className={styles.mandatoryText}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    id="business_name"
                    type="text"
                    autoComplete="business_name"
                    variant="outlined"
                    value={invoiceInformationFormik.values.business_name}
                    onChange={invoiceInformationFormik.handleChange}
                    error={
                      invoiceInformationFormik.touched.business_name &&
                      Boolean(invoiceInformationFormik.errors?.business_name)
                    }
                    helperText={
                      invoiceInformationFormik.touched.business_name &&
                      invoiceInformationFormik.errors?.business_name
                    }
                  />
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <Typography>
                    Giro <span className={styles.mandatoryText}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    id="activity"
                    type="text"
                    autoComplete="activity"
                    variant="outlined"
                    value={invoiceInformationFormik.values.activity}
                    onChange={invoiceInformationFormik.handleChange}
                    error={
                      invoiceInformationFormik.touched.activity &&
                      Boolean(invoiceInformationFormik.errors?.activity)
                    }
                    helperText={
                      invoiceInformationFormik.touched.activity &&
                      invoiceInformationFormik.errors?.activity
                    }
                  />
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <Typography>
                    Región <span className={styles.mandatoryText}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    select
                    id="region"
                    type="text"
                    autoComplete="region"
                    variant="outlined"
                    value={invoiceInformationFormik.values.region}
                    onChange={(event) =>
                      invoiceInformationFormik.setFieldValue('region', event.target.value)
                    }
                    error={
                      invoiceInformationFormik.touched.region &&
                      Boolean(invoiceInformationFormik.errors?.region)
                    }
                    helperText={
                      invoiceInformationFormik.touched.region &&
                      invoiceInformationFormik.errors?.region
                    }
                  >
                    {REGIONS.map((region) => (
                      <MenuItem key={region[0]} value={region[1]}>
                        {region[1]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <Typography>
                    Comuna <span className={styles.mandatoryText}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    select
                    id="commune"
                    type="text"
                    autoComplete="commune"
                    variant="outlined"
                    value={invoiceInformationFormik.values.commune}
                    onChange={(event) =>
                      invoiceInformationFormik.setFieldValue('commune', event.target.value)
                    }
                    error={
                      invoiceInformationFormik.touched.commune &&
                      Boolean(invoiceInformationFormik.errors?.commune)
                    }
                    helperText={
                      invoiceInformationFormik.touched.commune &&
                      invoiceInformationFormik.errors?.commune
                    }
                  >
                    {invoiceInformationFormik.values.region ? (
                      COMMUNES_BY_REGION[invoiceInformationFormik.values.region]
                        ?.sort()
                        .map((commune) => (
                          <MenuItem key={commune} value={commune}>
                            {commune}
                          </MenuItem>
                        ))
                    ) : (
                      <MenuItem>Selecciona región</MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <Typography>
                    Dirección <span className={styles.mandatoryText}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    id="address"
                    type="text"
                    autoComplete="address"
                    variant="outlined"
                    value={invoiceInformationFormik.values.address}
                    onChange={invoiceInformationFormik.handleChange}
                    error={
                      invoiceInformationFormik.touched.address &&
                      Boolean(invoiceInformationFormik.errors?.address)
                    }
                    helperText={
                      invoiceInformationFormik.touched.address &&
                      invoiceInformationFormik.errors?.address
                    }
                  />
                </Grid>
                <Grid item xs={12} className={styles.inputContainer}>
                  <Typography>Email contacto</Typography>
                  <TextField
                    fullWidth
                    id="email"
                    type="text"
                    autoComplete="email"
                    variant="outlined"
                    value={invoiceInformationFormik.values.email}
                    onChange={invoiceInformationFormik.handleChange}
                    error={
                      invoiceInformationFormik.touched.email &&
                      Boolean(invoiceInformationFormik.errors?.email)
                    }
                    helperText={
                      invoiceInformationFormik.touched.email &&
                      invoiceInformationFormik.errors?.email
                    }
                  />
                </Grid>
              </Fragment>
            ) : invoiceConfiguration?.ask_rut ? (
              <Fragment>
                <Grid item xs={12} className={styles.inputContainer}>
                  <Typography variant="h6" color={variables.primaryDarkerColor}>
                    <b>Datos boleta</b>
                  </Typography>
                  <Typography>
                    Rut <span className={styles.mandatoryText}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    required={true}
                    id="rut"
                    type="text"
                    autoComplete="extra_fields[id]"
                    variant="outlined"
                    value={invoiceInformationFormik.values.rut}
                    onChange={(e) =>
                      invoiceInformationFormik.setFieldValue('rut', formatRUT(e.target.value))
                    }
                    InputLabelProps={{ shrink: !!invoiceInformationFormik.values.rut }}
                    error={Boolean(invoiceInformationFormik.errors?.rut)}
                    helperText={invoiceInformationFormik.errors?.rut}
                  />
                </Grid>
              </Fragment>
            ) : null}
          </Fragment>
        )}

        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
          {extraActions}
          <Button
            disableElevation
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            disabled={props.loading}
            className="loader"
          >
            {props.loading && <CircularProgress size={20} />}
            {actionLabel}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
