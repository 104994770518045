import React, { Fragment, useEffect, useRef, useState, useImperativeHandle } from 'react';
import { Typography, Button, Box, CircularProgress, Drawer } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { es } from 'yup-locales';
import { intersection } from 'lodash';

import styles from './Client.module.scss';
import { ClientState } from './clientSlice';
import {
  ApiList,
  ApiObject,
  Card,
  CardInfo,
  GenericObject,
  KushkiResponse,
  PAC,
  TokenKushkiPayRequest,
} from '../../app/type';
import { useSelector } from 'react-redux';
import PopUp from '../../common/components/PopUp';
import KushkiCardForm from '../../common/components/KushkiCardForm';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { cardIcon, hexToRgb } from './utils';
import { clientApi } from '../../common/api';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import CardsPopup from './components/CardsPopup';
import { getFintoc } from '@fintoc/fintoc-js';
import { Kushki } from '@kushki/js';
import kushkiLogo from '../../assets/images/seller/payment/kushki.svg';

const kushki = new Kushki({
  merchantId:
    process.env.NODE_ENV === 'production'
      ? '1804b8f0b64549adb59dd76c6736124d'
      : 'c417cbc305344419a2c765e11ac7c7b3',
  inTestEnvironment: process.env.NODE_ENV !== 'production',
});
yup.setLocale(es);

const Cards = (): React.ReactElement => {
  const [addCardLoading, setAddCardLoading] = useState<boolean>(false);
  const [addPACLoading, setAddPACLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openUpdateCardPopup, setOpenUpdateCardPopup] = useState<boolean>(false);
  const [cards, setCards] = useState<Card[]>([]);
  const [PACs, setPACs] = useState<PAC[]>([]);
  const [oneclickResponse, setOneclickResponse] = useState<any>({});
  const [fintocResponse, setFintocResponse] = useState<any>({});
  const [cardToDelete, setCardToDelete] = useState<string>('');
  const [cardToUpdate, setCardToUpdate] = useState<string>('');
  const [openKushkiCard, setOpenKushkiCard] = useState<boolean>(false);
  const history = useHistory();
  const oneclickRef = useRef<HTMLFormElement>(null);
  const { client, company } = useSelector(({ client }: { client: ClientState }) => client);
  const { enqueueSnackbar } = useSnackbar();
  const reloadRef = useRef<{ reloadPACs: () => void }>();
  const rgbColor = company?.payment_design?.background_color
    ? hexToRgb(company?.payment_design?.background_color)
    : null;
  const cardsBackgroundColor = rgbColor
    ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)`
    : '#edeeff';

  const cardSchema = yup.object().shape({
    card_number: yup
      .string()
      .required('Número de tarjeta requerido')
      .max(19, 'Debe tener un largo máximo de 16 dígitos'),
    name: yup.string().required('Nombre del titular es requerido'),
    expiration_date: yup
      .string()
      .required('Fecha de vencimiento requerida')
      .min(5, 'Fecha inválida'),
    cvv: yup.string().required('CVV requerido').min(3, 'CVV inválido'),
  });

  const formik = useFormik<CardInfo>({
    initialValues: {
      card_number: '',
      name: '',
      expiration_date: '',
      cvv: undefined,
    },
    validationSchema: () => cardSchema,
    onSubmit: () => {
      setOpenKushkiCard(false);
      setAddCardLoading(true);
      kushki.requestSubscriptionToken(
        {
          currency: 'CLP',
          card: {
            name: formik.values.name,
            number: formik.values.card_number.split(' ').join(''),
            cvc: formik.values.cvv,
            expiryMonth: formik.values.expiration_date.slice(0, 2),
            expiryYear: formik.values.expiration_date.slice(3, 5),
          },
        } as TokenKushkiPayRequest,
        (response: KushkiResponse) => {
          if (!response.code) {
            clientApi.kushkiCards
              .create(client?.id || '', undefined, undefined, undefined, response.token)
              .then((data: any) => {
                if (data.data.approved) {
                  history.push(data.data.redirect_to);
                } else {
                  enqueueSnackbar('Ocurrió un error al agregar la tarjeta, intenta nuevamente', {
                    variant: 'error',
                  });
                  console.error(response.error);
                }
              })
              .catch(() => {
                enqueueSnackbar('Ocurrió un error al agregar la tarjeta, intenta nuevamente', {
                  variant: 'error',
                });
              })
              .finally(() => setAddCardLoading(false));
          } else {
            setAddCardLoading(false);
            enqueueSnackbar('Ocurrió un error al agregar la tarjeta, intenta nuevamente', {
              variant: 'error',
            });
            console.error(response.error);
          }
        }
      );
    },
  });

  const popupDeleteCard = (card_id: string) => {
    setCardToDelete(card_id);
    setOpenPopup(true);
  };

  const handleDeleteCard = () => {
    clientApi.cards
      .markAsInactive(client?.id || '', cardToDelete)
      .then((data: ApiObject<Card>) => {
        if (data.data.status === 'inactive') {
          history.replace('/client/card_success?deleted=true');
        } else if (data.data.has_subscriptions) {
          enqueueSnackbar('No puedes eliminar esta tarjeta porque está asociada a otro producto', {
            variant: 'error',
          });
        } else if (['active', 'pending_approval'].includes(data.data.status)) {
          enqueueSnackbar('No puedes eliminar esta tarjeta porque está asociada a otro producto', {
            variant: 'error',
          });
        }
      })
      .catch(() => {
        enqueueSnackbar('La tarjeta no pudo ser eliminada, intenta nuevamente', {
          variant: 'error',
        });
        console.error;
      });
    setOpenPopup(false);
  };

  const handleAddCard = () => {
    if (!addCardLoading) {
      if (company?.payment_methods?.includes('oneclick')) {
        setAddCardLoading(true);
        clientApi.webpayCards
          .create(client?.id || '', undefined, undefined, undefined)
          .then((data: GenericObject) => {
            setOneclickResponse(data.data);
          })
          .catch(console.error)
          .finally(() => setAddCardLoading(false));
      } else {
        setOpenKushkiCard(true);
      }
    }
  };

  const handleAddPAC = () => {
    if (!addCardLoading) {
      setAddPACLoading(true);
      if (company?.payment_methods?.includes('recurrent_fintoc')) {
        clientApi.fintocSubscriptions
          .createPAC(client?.id || '')
          .then((data: ApiObject<PAC>) => {
            setFintocResponse(data.data);
          })
          .catch(console.error)
          .finally(() => setAddPACLoading(false));
      } else if (company?.payment_methods?.includes('recurrent_et_pay')) {
        clientApi.etpaySubscriptions
          .createPAC(client?.id || '')
          .then((data: ApiObject<GenericObject>) => {
            data.data['approved']
              ? (window.location.href = data.data['redirect_url'])
              : enqueueSnackbar('Ha ocurrido un problema con el proveedor, intenta nuevamente', {
                  variant: 'error',
                });
          })
          .catch(console.error)
          .finally(() => setAddPACLoading(false));
      } else {
        clientApi.khipuSubscriptions
          .createPAC(client?.id || '')
          .then((data: ApiObject<GenericObject>) => {
            data.data['approved']
              ? (window.location.href = data.data['redirect_url'])
              : enqueueSnackbar('Ha ocurrido un problema con el proveedor, intenta nuevamente', {
                  variant: 'error',
                });
          })
          .catch(() => {
            console.error;
            enqueueSnackbar('Ha ocurrido un problema con el proveedor, intenta nuevamente', {
              variant: 'error',
            });
          })
          .finally(() => setAddPACLoading(false));
      }
    }
  };

  const updateCard = (id: string) => {
    setCardToUpdate(id);
    setOpenUpdateCardPopup(true);
  };

  const loadWebpayCards = () =>
    clientApi.webpayCards
      .list(client?.id || '')
      .then((data: ApiList<Card>) => {
        setCards((prevCards) => [...prevCards, ...data.data]);
      })
      .catch(console.error);

  // const loadKushkiCards = () =>
  //   clientApi.kushkiCards
  //     .list(client?.id || '')
  //     .then((data: ApiList<Card>) => {
  //       setCards((prevCards) => [...prevCards, ...data.data]);
  //     })
  //     .catch(console.error);

  const loadFintocPACs = () =>
    clientApi.fintocSubscriptions
      .list(client?.id || '')
      .then((data: ApiList<PAC>) => {
        setPACs(data.data);
      })
      .catch(console.error);
  const loadEtpayPACs = () =>
    clientApi.etpaySubscriptions
      .list(client?.id || '')
      .then((data: ApiList<PAC>) => {
        setPACs(data.data);
      })
      .catch(console.error);

  // const loadKhipuPACs = () =>
  //   clientApi.khipuSubscriptions
  //     .list(client?.id || '')
  //     .then((data: ApiList<PAC>) => {
  //       setPACs((prevPACS) => [...prevPACS, ...data.data]);
  //     })
  //     .catch(console.error);

  useEffect(() => {
    const queries: Promise<any>[] = [new Promise((resolve) => setTimeout(resolve, 2000))];
    setLoading(true);
    const webpayCardsQuery = loadWebpayCards();
    // const kushkiCardsQuery = loadKushkiCards();
    const fintocPacsQuery = loadFintocPACs();
    const etpayPacsQuery = loadEtpayPACs();
    // const khipuPacsQuery = loadKhipuPACs();
    queries.push(webpayCardsQuery);
    queries.push(fintocPacsQuery);
    queries.push(etpayPacsQuery);
    // queries.push(khipuPacsQuery);
    // queries.push(kushkiCardsQuery);
    Promise.all(queries).finally(() => {
      setLoading(false);
    });
  }, [client?.id]);

  useEffect(() => {
    if (oneclickResponse.url && oneclickRef?.current) oneclickRef?.current.submit();
  }, [oneclickResponse]);

  useEffect(() => {
    if (Object.keys(fintocResponse).length !== 0) {
      triggerFintoc();
    }
  }, [fintocResponse]);

  const triggerFintoc = () => {
    getFintoc().then((Fintoc: any) => {
      const widget = Fintoc.create({
        widgetToken: fintocResponse?.widget_token,
        publicKey: fintocResponse?.public_key,
        holderType: 'individual',
        product: 'subscriptions',
        webhookUrl: fintocResponse?.webhook_url,
        onSuccess: () => {
          enqueueSnackbar('Inscripción completada, te enviaremos un correo con los detalles', {
            variant: 'success',
          });
          reloadRef.current?.reloadPACs();
        },
        onExit: () => {
          enqueueSnackbar('Ha ocurrido un problema con el proveedor, intenta de nuevo', {
            variant: 'error',
          });
        },
      });
      widget.open();
    });
  };

  useImperativeHandle(reloadRef, () => ({
    reloadPACs() {
      loadFintocPACs();
      loadEtpayPACs();
      // loadKhipuPACs();
    },
  }));

  return (
    <div className={styles.cardsContainer}>
      {loading ? (
        <div className={styles.spinnerContainer}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress
              sx={{
                color: company?.payment_design?.background_color
                  ? company?.payment_design?.background_color
                  : '#4653e3',
              }}
            />
          </Box>
        </div>
      ) : (
        <Fragment>
          <div className={styles.tabTitle}>
            <Typography>Medios de pago</Typography>
          </div>
          <div className={styles.description}>
            <Typography>
              Acá puedes ver todos los medios de pagos que has registrado para pagar de forma
              automática tus productos y servicios.
            </Typography>
          </div>
          {company?.payment_methods?.includes('oneclick') && (
            <div className={styles.cardsBox}>
              <Typography>Tarjetas (Cobro automático a tu tarjeta)</Typography>
              <div className={styles.cardsRow}>
                {cards.map((card: Card) => (
                  <div
                    className={styles.box}
                    key={card.id}
                    style={{
                      background: cardsBackgroundColor,
                    }}
                  >
                    {card.transaction_rejected ? (
                      <div className={styles.actions}>
                        <div className={styles.warningRow}>
                          <div className={styles.message}>
                            <FontAwesomeIcon
                              icon={faTriangleExclamation}
                              className={styles.warningIcon}
                            />
                            <Typography>Transacción rechazada</Typography>
                          </div>
                          <div className={styles.delete} onClick={() => updateCard(card.id)}>
                            <Typography>Actualizar</Typography>
                          </div>
                        </div>
                        <div className={styles.delete} onClick={() => popupDeleteCard(card.id)}>
                          <Typography>Eliminar</Typography>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.delete} onClick={() => popupDeleteCard(card.id)}>
                        <Typography>Eliminar</Typography>
                      </div>
                    )}

                    <div className={styles.card}>
                      <div className={styles.cardIcon}>
                        {card.emisor ? (
                          <img src={cardIcon(card.emisor)} />
                        ) : (
                          <Typography>Prepago</Typography>
                        )}
                      </div>
                      <div className={styles.cardNumber}>
                        <Typography>{`XXXX XXXX XXXX ${card.last_four_digits}`}</Typography>
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  className={`${styles.box} ${styles.newCardBox}`}
                  style={{
                    background: cardsBackgroundColor,
                  }}
                  onClick={handleAddCard}
                >
                  {addCardLoading ? (
                    <div className={styles.cardSpinnerContainer}>
                      <Box sx={{ display: 'flex' }}>
                        <CircularProgress
                          sx={{
                            color: company?.payment_design?.background_color
                              ? company?.payment_design?.background_color
                              : '#4653e3',
                          }}
                        />
                      </Box>
                    </div>
                  ) : (
                    <Fragment>
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={styles.plusIcon}
                        style={{
                          color: company?.payment_design?.background_color
                            ? company?.payment_design?.background_color
                            : '#4653e3',
                        }}
                      />
                      <Typography>Agregar nueva tarjeta</Typography>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          )}

          {intersection(company?.payment_methods, [
            'recurrent_fintoc',
            'recurrent_khipu',
            'recurrent_et_pay',
          ]).length > 0 && (
            <div className={styles.cardsBox}>
              <Typography>PAC (Cobro automático a tu cuenta)</Typography>
              <div className={styles.cardsRow}>
                {PACs.map((pac: PAC) => (
                  <div
                    className={styles.box}
                    key={pac.id}
                    style={{
                      background: cardsBackgroundColor,
                    }}
                  >
                    {pac.transaction_rejected ? (
                      <div className={styles.actions}>
                        <div className={styles.warningRow}>
                          <div className={styles.message}>
                            <FontAwesomeIcon
                              icon={faTriangleExclamation}
                              className={styles.warningIcon}
                            />
                            <Typography>Transacción rechazada</Typography>
                          </div>
                          <div className={styles.delete} onClick={() => updateCard(pac.id)}>
                            <Typography>Actualizar</Typography>
                          </div>
                        </div>
                        <div className={styles.delete} onClick={() => popupDeleteCard(pac.id)}>
                          <Typography>Eliminar</Typography>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.delete} onClick={() => popupDeleteCard(pac.id)}>
                        <Typography>Eliminar</Typography>
                      </div>
                    )}

                    <div className={styles.pac}>
                      <div className={styles.cardIcon}>
                        <Typography>{pac.bank_name}</Typography>
                        <Typography variant="subtitle1">
                          {['pending_approval', 'pending'].includes(pac.status)
                            ? 'Pendiente de aprobación'
                            : 'Activo'}
                        </Typography>
                      </div>
                      <div className={styles.cardNumber}>
                        <Typography variant="subtitle1">{pac.account_type}</Typography>
                        <Typography>{`•••• ${pac.account_number?.slice(-4) || ''}`}</Typography>
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  className={`${styles.box} ${styles.newCardBox}`}
                  onClick={handleAddPAC}
                  style={{
                    background: cardsBackgroundColor,
                  }}
                >
                  {addPACLoading ? (
                    <div className={styles.cardSpinnerContainer}>
                      <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                      </Box>
                    </div>
                  ) : (
                    <Fragment>
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={styles.plusIcon}
                        style={{
                          color: company?.payment_design?.background_color
                            ? company?.payment_design?.background_color
                            : '#4653e3',
                        }}
                      />
                      <Typography>Agregar PAC</Typography>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          )}

          <PopUp
            state={{ open: openPopup, setOpen: setOpenPopup }}
            title={
              <Typography variant="h6" align="center">
                Advertencia
              </Typography>
            }
            content={
              <Typography sx={{ marginTop: '20px' }} variant="body1" align="center">
                Se eliminará la tarjeta. Esta acción es irreversible. ¿Quieres continuar?
              </Typography>
            }
            extraActions={[
              <Button onClick={handleDeleteCard} color="error" variant="contained" key={1}>
                Sí
              </Button>,
              <Button onClick={() => setOpenPopup(false)} variant="outlined" key={2}>
                No
              </Button>,
            ]}
          />
          <Drawer anchor="right" open={openKushkiCard} onClose={() => setOpenKushkiCard(false)}>
            <div className={styles.kushkiCardContainer}>
              <Typography variant="h5" align="center">
                Inscripción tarjeta
              </Typography>
              <div className={styles.form}>
                <KushkiCardForm formik={formik} />
              </div>
              <img className={styles.kushkiLogo} src={kushkiLogo} />
              <div className={styles.actions}>
                <Button onClick={() => setOpenKushkiCard(false)} variant="outlined">
                  Cancelar
                </Button>
                <Button onClick={() => formik.submitForm()} variant="contained">
                  Guardar tarjeta
                </Button>
              </div>
            </div>
          </Drawer>
          <CardsPopup
            state={{ open: openUpdateCardPopup, setOpen: setOpenUpdateCardPopup }}
            card={cardToUpdate || ''}
            updateCard={true}
            cards={cards}
            PACs={PACs}
          />
          {oneclickResponse.url && oneclickResponse.token && (
            <form method="POST" action={oneclickResponse.url} ref={oneclickRef}>
              <input type="hidden" name="TBK_TOKEN" value={oneclickResponse.token} />
            </form>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Cards;
